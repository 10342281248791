import i18n from 'Utils/I18n';

export const footerSections = () => [
  
    {
        text: i18n.t('navLinks.aboutUs'),
        path: '/about',
    },
    // {
    //     text: i18n.t('navLinks.library'),
    //     path: '/molem-library',
    // },
    {
        text: i18n.t('navLinks.khalijiWeek'),
        path: '/khaliji-investor-week',
    },
    {
        text: i18n.t('navLinks.khalijiAwards'),
        path: '/smart-khaliji-investor-award',
    },
    {
        text: i18n.t('navLinks.contactUs'),
        path: '/contact',
    },
    // {
    //     title: i18n.t('footer.forInvestors'),
    //     links: [
    //         {
    //             text: i18n.t('footer.whyInvest'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.howItWorks'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.faq'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.risks'),
    //             href: '#',
    //         },
    //     ],
    // },
    // {
    //     title: i18n.t('footer.forInvestors'),
    //     links: [
    //         {
    //             text: i18n.t('footer.whyInvest'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.howItWorks'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.faq'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.risks'),
    //             href: '#',
    //         },
    //     ],
    // },
    // {
    //     title: i18n.t('footer.forInvestors'),
    //     links: [
    //         {
    //             text: i18n.t('footer.whyInvest'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.howItWorks'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.faq'),
    //             href: '#',
    //         },
    //         {
    //             text: i18n.t('footer.risks'),
    //             href: '#',
    //         },
    //     ],
    // },
];
