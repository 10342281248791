import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import SafeHTML from './SafeHTML/SafeHTML';

const ExpandMoreIcon = () => (
  <svg
    style={{
      marginRight: '8px',
    }}
    xmlns='http://www.w3.org/2000/svg'
    width='28.481'
    height='28.481'
    viewBox='0 0 28.481 28.481'
  >
    <path
      d='M63.443,126.853V100L50.017,113.426Z'
      transform='translate(-106.078 -25.85) rotate(-45)'
      fill='rgb(159 208 155)'
    ></path>
  </svg>
);

export default function AccordionWrap({ items, openAll = false }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return items.map((item, index) => {
    let panel = `panel${index + 1}`;
    return (
      <Accordion
        key={panel}
        expanded={expanded === panel || openAll}
        onChange={handleChange(panel)}
        style={{
          boxShadow: 'none',
          margin: '0px',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          style={{
            flexDirection: 'row-reverse',
            borderBottom: '1px solid #E5E5E5',
          }}
        >
          <Typography style={{
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            color: 'black',
            marginBottom: '0',
          }} width="100%" variant='h6' component={'h6'} padding='8px 16px'
          >
            {item?.heading}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SafeHTML
            variant='body1'
            component={'div'}
            style={{
              margin: '20px 30px',
            }}>
            {item?.description}
          </SafeHTML>
        </AccordionDetails>
      </Accordion>
    );
  });
}
