import { Card, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import yup from "Utils/Yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Btn, InputController, setProgress } from "Components";
import { useTranslation } from "react-i18next";
import twiterIcon from "Assets/commingSoon/twitterIcon.svg";
import instagramIcon from "Assets/commingSoon/instagramIcon.svg";
import youtubeIcon from "Assets/commingSoon/youtubeIcon.svg";
import email from "Assets/contactUs/email.svg";
import { color1 } from "Pages/Constant";
import TitleBox from "Components/TitleBox/TitleBox";
import HelmetWrapper from "Components/HelmetWrapper/HelmetWrapper";

import WebBanners08 from "Assets/Home/carosoel/InnerPageBanners/AboutBanner.png";
import { http } from "Utils/Http/Http";
import { toast } from "react-toastify";
import { countries } from "Constants/countries";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: "capitalize",
    fontSize: "18px",
    color: "rgba(0,0,0,1)",
    borderTop: "1px solid #E5E5E5",
  },
  contactDetailsLabel: {
    color: color1,
    fontWeight: "normal",
    fontSize: "30px",
    padding: "20px",
  },
  contactDetailsText: {
    color: "color1",
    fontSize: "16px",
    padding: "10px",
    // border: "1px solid black",
    borderRadius: "10px",
    textTransform: "none",
  },
  formHeading: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "34px",
  },
}));

const schema = yup.object().shape({
  sponserName: yup.string().required("sponserNameRequired"),
  country: yup.string().required("countryRequired"),
  city: yup.string().required("cityRequired"),
  name: yup.string().required("nameRequired"),
  email: yup.string().required("emailRequired").email("emailInvalid"),
  mobile: yup.string().required("mobileRequired"),
  message: yup
    .string()
    .required("messageRequired")
    .min(6, "messageMin")
    .max(200, "messageMax"),
});

export default function SponserForm() {
  const classes = useStyles();
  const { t } = useTranslation();

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, reset } = form;

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    try {
      await http
        .post(`/api/v1/contact-us`, { ...values, topic: "طلب رعاية" })
        .then(async (res) => {
          toast.success(t("toast.success.sentSuccessfully"));
          reset({
            name: "",
            email: "",
            message: "",
            mobile: "",
            country: "",
            city: "",
            sponsorName: "",
          });
        })
        .catch(async (error) => {
          toast.error(t("toast.error.sometingWentWrong"));
          // toast.error(error?.response?.statusText);
        });
    } catch (res) {
      toast.error(res?.response?.data?.message);
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <div className={classes.contactUs}>
      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        style={{ padding: "0", marginBottom: "10vh", marginTop: "50px" }}
      >
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <h2
            style={{
              color: color1,
              textAlign: "center",
              fontSize: 26,
              marginBottom: 30,
            }}
          >
            نموذج الجهات الراغبة في رعاية أعمال جائزة المستثمر الذكي الخليجي
            2023
          </h2>
          <h3 style={{ color: color1, textAlign: "center", fontSize: 18 }}>
            يسعدنا استقبال طلباتكم لرعاية أعمال جائزة المستثمر الذكي الخليجي من
            خلال تعبئة النموذج أدناه ليتواصل معكم فريق الجائزة!
          </h3>
          <Card
            variant="outlined"
            style={{
              borderRadius: "20px",
              margin: "5%",
            }}
          >
            <form onSubmit={handleSubmit(handleFormSubmission)}>
              <Grid
                container
                spacing={10}
                justifyContent="center"
                style={{ padding: "20px" }}
              >
                <Grid item xs={12} md={6}>
                  <InputController
                    size="small"
                    control={control}
                    name="name"
                    placeholder="Enter your name*"
                    label={t("contactUs.create.name")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputController
                    size="small"
                    control={control}
                    name="email"
                    placeholder="Enter your email address*"
                    label={t("contactUs.create.email")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputController
                    size="small"
                    control={control}
                    name="sponserName"
                    placeholder="Enter sponser number*"
                    label={t("contactUs.create.sponserName")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputController
                    size="small"
                    control={control}
                    name="mobile"
                    placeholder="Enter sponser name*"
                    label={t("contactUs.create.mobile")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputController
                    size="small"
                    select
                    control={control}
                    name="country"
                    label={t("contactUs.create.countries")}
                    MenuProps={{
                      sx: {
                        maxHeight: 250,
                      },
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem value={country.name}>{country.name}</MenuItem>
                    ))}
                  </InputController>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputController
                    size="small"
                    control={control}
                    name="city"
                    placeholder="Enter city name*"
                    label={t("contactUs.create.city")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    size="small"
                    control={control}
                    name="message"
                    placeholder="Write your message"
                    multiline={true}
                    minRows={4}
                    maxRows={6}
                    label={t("contactUs.create.message")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Btn
                    type="submit"
                    name={CREATE_PAYOUT_BTN}
                    style={{ textTransform: "none", textAlign: "center" }}
                    fullWidth
                  >
                    {t("contactUs.contactUs")}
                  </Btn>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
