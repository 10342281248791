import { Grid, Button, Card, Avatar, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MaskGroup2 from "Assets/Home/MaskGroup2.png";
import about from "Assets/Home/about.png";
import arrowRight from "Assets/arrowRight.svg";
import arrowLeft from "Assets/arrowLeft.svg";
import twitterBlue from "Assets/twitterBlue.svg";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import Carousel, { consts } from "react-elastic-carousel";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { color1, color2 } from "Pages/Constant";

import quoteBlue from "Assets/quoteBlue.svg";
import MyCarousel from "./Components/StackeCarousel";
import { useState, useEffect } from "react";
import { http, httpTwitter } from "Utils/Http/Http";
import Logo from "Assets/logoSquare.svg";
import { useQuery } from "react-query";
import { HOMEDETAIL } from "Constants/QueriesKeys";

import { CardContent, CardMedia } from "@mui/material";

import React from "react";

import Country01 from "Assets/aboutUs/Country01.png";
import Country02 from "Assets/aboutUs/Country02.png";
import Country03 from "Assets/aboutUs/Country03.png";
import Country04 from "Assets/aboutUs/Country04.png";
import Country05 from "Assets/aboutUs/Country05.png";
import Country06 from "Assets/aboutUs/Country06.png";
import WebsiteLogo1 from "Assets/aboutUs/Asset1.png";
import WebsiteLogo2 from "Assets/aboutUs/Asset2.png";
import WebsiteLogo3 from "Assets/aboutUs/Asset3.png";
import WebsiteLogo4 from "Assets/aboutUs/FSA Logo.png";
import WebsiteLogo5 from "Assets/aboutUs/Asset5.png";
import WebsiteLogo6 from "Assets/aboutUs/Asset6.png";
import footerLogo from "Assets/footerLogo.png";

import Fade from "@mui/material/Fade";

import AccordionFullWidthAPI from "Components/AccordionFullwidthAPI";

import { ABOUTDETAIL } from "Constants/QueriesKeys";

import Fab from "@mui/material/Fab";

// import FavoriteIcon from "@mui/icons-material/Favorite";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const useStyles = makeStyles((theme) => ({
  bannerText: {
    fontSize: "85px",
    lineHeight: "107px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "white",
    width: "450px",
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
      lineHeight: "65px",
      padding: "0px 100px 0 0",
      width: "100%",
      textAlign: "center",
      maxWidth: "40vw",
      marginBottom: "0",
      textAlign: "right",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "32px",
      padding: "0px 50px 0 0",
    },
  },
  about: {
    fontSize: "17px",
    lineHeight: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  section__headline: {
    color: color1,
    textAlign: "center",
    fontSize: "60px",
    fontWeight: "bold",
    lineHeight: "26px",
    textAlign: "center",
    marginBottom: "100px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      marginBottom: "60px",
    },
  },
  section2: {
    padding: "160px 5%",
    textAlign: "justify",
    // background: `url(${MaskGroup1})`,
    minHeight: "calc(100vh - 118px)",
    // minHeight: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    position: "relative",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
      padding: "100px 5%",
    },
  },
  about__bg: {
    background: `url(${about})`,
    backgroundSize: "auto 80%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    position: "absolute",
    left: "0",
    top: "0",
    bottom: "0",
    width: "200px",
    transform: "rotateY(-180deg)",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  section3: {
    padding: "160px 5%",
    textAlign: "justify",
    minHeight: "calc(100vh - 118px)",
    // minHeight: '100vh',
    background: `url(${MaskGroup2})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    background: "#F8F8F8",
    [theme.breakpoints.down("md")]: {
      padding: "100px 5%",
      minHeight: "auto",
    },
  },
  image1: {
    display: "block",
    margin: "0 auto",
  },
  image2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  exploreMore: {
    margin: "20px",
    textDecoration: "none",
    fontSize: "20px",
    lineHeight: "26px",
    backgroundColor: "transparent",
    color: color1,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconColor: {
    color: "black",
    margin: "0 10px",
    height: "28px",
  },
  iconColor1: {
    color: "black",
    margin: "0",
    height: "28px",
  },
  messages: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "26px",
    color: color1,
    margin: "20px",
  },
  messageHeading: {
    fontSize: "48px",
    fontWeight: "bold",
    margin: "20px",
  },
  bnnrH1: {
    color: "#fff",
    fontSize: "70px",
  },
  tweetCard: {
    padding: "82px 40px 60px 40px",
    boxShadow: "0px 4px 40px rgba(43, 89, 255, 0.08)",
    borderRadius: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "60px 16px 40px 16px",
    },
  },
  tweetCard212: {
    // background: `url(${tweetCard})`,
    background: `url(${quoteBlue})`,
    backgroundSize: "100px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
  },
  carousel: {
    position: "relative",
    "& .rec-slider-container": {
      margin: "0 auto",
    },
    "& .rec-item-wrapper": {
      height: "100%",
    },
  },
  bannerButton: {
    background:
      "linear-gradient(90deg, rgba(0, 0, 0, 0) 2.75%, #000000 96.22%)",
    opacity: "0.5",
    transform: "rotate(-180deg)",
    width: "180px",
    height: "100%",
    borderRadius: "0px",
    position: "absolute",
    top: "0",
    bottom: "0",
    zIndex: "1",
    "&:hover": {
      opacity: ".7",
    },
    "&:first-child": {
      background:
        "linear-gradient(90deg, #000000 2.75%, rgba(0, 0, 0, 0) 96.22%)",
      right: "0",
    },
    "&:nth-child(3)": {
      left: "0",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80px",
    },
    "&.Mui-disabled": {
      display: "none",
    },
  },
  bannerButton__iconWrap: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    background: "rgba(237, 237, 237, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotate(-180deg)",
    [theme.breakpoints.down("sm")]: {
      width: "30px",
      height: "30px",
      borderRadius: "30px",
    },
  },
  carosoel_main: {
    width: "100%",
    minHeight: "calc(100vh - 118px)",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      minHeight: "50vmin",
    },
  },
  avatar: {
    margin: "0 16px",
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      width: "50px",
    },
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
  },
  avatar__twitter: {
    position: "absolute",
    top: "0",
    left: "14px",
    [theme.breakpoints.down("sm")]: {
      left: "10px",
    },
  },
  avatar__deatils: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

const Home = () => {
  const { t, i18n } = useTranslation();
  let { path } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [aboutArray, setAboutArray] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 100) {
      // You can adjust this value
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const breakPoints1 = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1500, itemsToShow: 1 },
  ];
  const [bannerList, setBannerList] = useState();

  const countriesMemberList = [
    {
      title: "emirates flag",
      logo: Country01,
      path: "https://www.sca.gov.ae/",
    },
    {
      title: "bahrain flag",
      logo: Country02,
      path: "https://www.cbb.gov.bh/ar/",
    },
    {
      title: "saudi flag",
      logo: Country03,
      path: "https://cma.org.sa/Pages/default.aspx",
    },
    {
      title: "oman flag",
      logo: Country04,
      path: "https://cma.gov.om/",
    },
    {
      title: "qatar flag",
      logo: Country05,
      path: "https://www.qfma.org.qa/Arabic/Pages/default.aspx",
    },
    {
      title: "kuwait",
      logo: Country06,
      path: "https://www.cma.gov.kw/ar/web/cma",
    },
  ];
  const websiteLogo = [
    { logo: WebsiteLogo6, href: "https://www.sca.gov.ae/", links: [] },
    { logo: WebsiteLogo1, href: "https://www.cbb.gov.bh/ar/", links: [] },
    {
      logo: WebsiteLogo3,
      href: "https://cma.org.sa/en/Pages/default.aspx",
      links: [
        {
          title: "المؤسسات المالية المرخص لها",
          url: "https://cma.org.sa/Market/AuthorisedPersons/Pages/default.aspx",
        },
        {
          title: "الجهات الغير مرخص لها",
          url: "https://cma.org.sa/Awareness/Pages/Forex.aspx",
        },
      ],
    },
    {
      logo: WebsiteLogo4,
      href: "https://cma.gov.om/",
      links: [
        {
          title: "الجهات المرخصة",
          url: "https://cma.gov.om/Home/AuthorizedAndAccredited",
        },
      ],
    },
    {
      logo: WebsiteLogo2,
      href: "https://www.qfma.org.qa/Arabic/Pages/default.aspx",
      links: [
        {
          title: "الشركات",
          url: "https://www.sca.gov.ae/ar/open-data/companies.aspx",
        },
        {
          title: "الخدمات المالية",
          url: "https://www.sca.gov.ae/ar/open-data/financial-services.aspx",
        },
        {
          title: "الوسطاء",
          url: "https://www.sca.gov.ae/ar/open-data/brokers.aspx",
        },
        {
          title: "صناديق الاستثمار",
          url: "https://www.sca.gov.ae/ar/open-data/mutual-funds.aspx",
        },
      ],
    },
    {
      logo: WebsiteLogo5,
      href: "https://www.cma.gov.kw/ar/web/cma",
      links: [],
    },
  ];
  // const bannerList = [
  //     {
  //         heading: "برنامج التوعية الاستثمارية الخليجي",
  //         headerAlign: "flex-start",
  //         backgroundImage: HomeBanner
  //     },
  //     {
  //         heading: "عن مُلم",
  //         headerAlign: "flex-start",
  //         link: '/about',
  //         backgroundImage: AboutBanner
  //     },
  //     // {
  //     //     heading: t("navLinks.library"),
  //     //     headerAlign: "flex-start",
  //     //     link: '/molem-library',
  //     //     backgroundImage: LibraryBanner
  //     // },
  // ];
  const [tweets, setTweets] = useState([]);
  const [tweetsProfile, setTweetsProfile] = useState({
    name: "",
    username: "",
    id: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await httpTwitter.get(`/api/v1/twitter/list`);
      setTweets(result?.data?.data?.tweets?.data);
      setTweetsProfile({
        ...tweetsProfile,
        ...result?.data?.data?.profileInfo,
      });
    };
    fetchData();
  }, []);

  const [homePageDetails, setHomePageDetails] = useState();

  const homePageQuery = useQuery([HOMEDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      return (
        data,
        setHomePageDetails(data?.data?.homePage),
        setBannerList(data?.data?.homePage?.carouselDetails)
      );
    })
  );

  const aboutPageQuery = useQuery([ABOUTDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      return data, setAboutArray(data?.data?.aboutPage?.aboutCardDetails);
    })
  );

  const navigateTo = (link) => {
    if (!link) return;

    // @eslint-ignore
    window.dataLayer.push("event", "click", {
      event_category: "general",
      event_label: link,
    });

    history.push(link);
  };

  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        i18n.language === "ar" ? (
          <ArrowForwardIosIcon className={classes.iconColor1} />
        ) : (
          <ArrowBackIosNewIcon className={classes.iconColor1} />
        )
      ) : i18n.language === "ar" ? (
        <ArrowBackIosNewIcon className={classes.iconColor1} />
      ) : (
        <ArrowForwardIosIcon className={classes.iconColor1} />
      );
    return (
      <Button
        onClick={onClick}
        className={classes.bannerButton}
        disabled={isEdge}
        style={{ minWidth: "0px", padding: "0" }}
      >
        <span className={classes.bannerButton__iconWrap}>{pointer}</span>
      </Button>
    );
  }

  return (
    <>
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Carousel
            className={classes.carousel}
            isRTL={i18n.language === "ar" ? true : false}
            disableArrowsOnEnd
            breakPoints={breakPoints1}
            pagination={false}
            enableAutoPlay={true}
            autoPlaySpeed={5000}
            enableSwipe={true}
            showArrows={true}
            style={{ margin: "0 0" }}
            renderArrow={myArrow}
          >
            {bannerList?.map((item, index) => {
              return (
                <Box
                  key={index}
                  className={classes.carosoel_main}
                  id={item?.link + "-carousel"}
                  style={{
                    background: `url(${item.backgroundImage})`,
                  }}
                  onClick={() => navigateTo(item?.link)}
                >
                  <Grid
                    container
                    style={{ height: "100%" }}
                    justifyContent={item?.headerAlign}
                    alignItems="center"
                    id={item?.link + "-carousel"}
                  >
                    <Grid
                      item
                      style={{
                        textAlign: "center",
                        padding: "5%",
                        color: "#fff",
                      }}
                    >
                      {item?.link ? (
                        <h1
                          className={classes.bannerText}
                          style={{
                            cursor: "pointer",
                            color: item?.headingColor,
                          }}
                        >
                          {item?.heading}
                        </h1>
                      ) : (
                        <h1
                          className={classes.bannerText}
                          style={{
                            color: item?.headingColor,
                          }}
                        >
                          {item?.heading}
                        </h1>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Carousel>
        </Grid>
      </Grid>

      <Grid
        id="about-us"
        container
        direction="row"
        justifyContent="center"
        className={classes.section2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.about__bg}
        ></Grid>
        <Grid
          sx={{ margin: "0 auto" }}
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <Grid container>
            <Grid item xs={12}>
              <h2 className={classes.section__headline}>{t("home.text")}</h2>
              <br />
              <div className={classes.about}>
                <div
                  className="editor"
                  dangerouslySetInnerHTML={{
                    __html: homePageDetails?.aboutMulim,
                  }}
                ></div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "12px" }}>
              {/* <Button
                style={{
                  color: color2,
                  fontSize: "20px",
                  fontWeight: "bold",
                  padding: "0",
                }}
                onClick={() => history.push("/about")}
              >
                {t("home.readMore")}{" "}
                {i18n.language === "ar" ? (
                  <img src={arrowLeft} className={classes.iconColor} />
                ) : (
                  <img src={arrowRight} className={classes.iconColor} />
                )}
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid container justifyContent="center" className={classes.section3}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <h2 className={classes.section__headline}>
                {t("home.latestTweets")}
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={10} style={{ heigt: "400px" }}>
              <MyCarousel>
                {tweets?.length > 0 &&
                  tweets.map((item) => {
                    return (
                      <Card
                        key={item.id}
                        className={classes.tweetCard}
                        onClick={() =>
                          window.open(
                            `https://twitter.com/${tweetsProfile.username}/status/${item.id}`
                          )
                        }
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className={classes.tweetCard212}
                        >
                          <Grid item xs={12} style={{ marginBottom: "20px" }}>
                            <Grid container justifyContent="center">
                              <Grid item style={{ maxWidth: "400px" }}>
                                <p style={{ textAlign: "center" }}>
                                  {item?.text}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid
                                style={{
                                  position: "relative",
                                }}
                                item
                              >
                                <Avatar
                                  src={Logo}
                                  alt="Tweets"
                                  className={classes.avatar}
                                />
                                <span className={classes.avatar__twitter}>
                                  <img src={twitterBlue} alt="Twitter Icon" />
                                </span>
                              </Grid>
                              <Grid item>
                                <Grid
                                  container
                                  justifyContent="center"
                                  className={classes.avatar__deatils}
                                >
                                  <Grid item xs={12}>
                                    <span>{`@${tweetsProfile.username}`}</span>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <span>{tweetsProfile.name}</span>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })}
              </MyCarousel>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Box
        style={{
          backgroundColor: "#F8F8F8",
        }}
      >
        <Grid
          maxWidth={"xl"}
          container
          margin={"0 auto"}
          alignItems="center"
          justifyContent="center"
          style={{
            padding: "5%",
            marginBottom: "10vh",
          }}
        >
          <Grid item xs={12}>
            {aboutArray && <AccordionFullWidthAPI items={aboutArray} />}
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        maxWidth={"xl"}
        alignItems="center"
        style={{ margin: "0 auto", paddingLeft: "2.5%" }}
      >
        <Grid item xs={12} md={3} lg={2}>
          <img
            src={footerLogo}
            alt=""
            style={{
              width: "100%",
              maxWidth: "300px",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "30px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={9} lg={10} className={classes.websiteLogoMain}>
          <Box
            container
            sx={{
              alignItems: "stretch",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {websiteLogo?.map((item) => {
              return (
                <Box sx={{ width: 100 / 3 + "%", padding: 2 }}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    href={item?.href}
                  >
                    <a
                      target="_black"
                      href={item?.href}
                      style={{ padding: 16 }}
                    >
                      <CardMedia
                        component="img"
                        // className={classes.websiteLogo}
                        height="194"
                        image={item?.logo}
                        alt="Paella dish"
                        sx={{
                          objectFit: "contain",
                          height: "125px",
                          marginX: "auto",
                          width: "auto",
                        }}
                      />
                    </a>
                    <CardContent>
                      {/* {item?.links?.map((link) => (
                        <Box sx={{ marginTop: 4 }}>
                          <a href={link.url}>{link.title}</a>
                        </Box>
                      ))} */}
                    </CardContent>
                  </Card>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
      <div
        style={{ padding: "0 5%", marginBottom: "100px", marginTop: "100px" }}
      >
        <Grid
          container
          spacing={3}
          style={{ maxWidth: "700px", marginLeft: "auto", marginRight: "auto" }}
        >
          {countriesMemberList?.map((item) => {
            return (
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                alignItems="center"
                justifyContent="center"
                style={{ display: "flex", marginBottom: "5%" }}
              >
                {/* <Tooltip title={item?.path} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}> */}
                <a
                  target="blank"
                  href={item?.path}
                  style={{ borderRadius: "50%" }}
                >
                  <img
                    src={item.logo}
                    alt=""
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                  />
                </a>
                {/* </Tooltip> */}
              </Grid>
            );
          })}
        </Grid>
      </div>
      {/* {isVisible && (
        <Fade in={isVisible} o>
          <Fab
            onClick={() => navigateTo("prev-winners")}
            sx={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
              // opacity: isVisible ? 1 : 0,
              // transition: "opacity 1.3s ease-in-out",
            }}
            aria-label="like"
            variant="extended"
          >
            <Typography sx={{ marginX: 2 }}>
              اطلع على الفائزين في 2022
            </Typography>
            <EmojiEventsIcon />
          </Fab>
        </Fade>
      )} */}
    </>
  );
};
export default Home;
