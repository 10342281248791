import {
  Card,
  Grid,
  Typography,
  Box,
  CardMedia,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import RewardMoney from "./Components/RewardMoney";
import FormModal from "./Components/FormModal";
import AwardCommonModal from "./Components/AwardCommonModal";
import FormSubmitModal from "./Components/FormSubmitModal";
import FlipcardHex from "./Components/FlipCardHex/FlipcardHex";
import { color2 } from "Pages/Constant";
import styles from "./Components/FlipCardHex/FlipcardHex.module.css";

import FAQPolicyCard from "Assets/awards/FAQPolicyCard.svg";
import VideoIconColored from "Assets/awards/VideoIconColored.png";
import PhotoIconColored from "Assets/awards/PhotoIconColored.png";
import PencilIconColored from "Assets/awards/PencilIconColored.png";
import WritingIconColored from "Assets/awards/writing.png";
import AwardIconColored from "Assets/awards/AwardIconColored.png";

// participant category icons
import ProfessionalIcon from "Assets/participantCategories/prof.png";
import SchoolIcon from "Assets/participantCategories/school.png";
import UniversityIcon from "Assets/participantCategories/university.png";

//
import SponserArgam from "../../Assets/sponsers/Argam.png";
import SponserTetco from "../../Assets/sponsers/Tetco.png";
// import Accordion from 'Components/Accordion';
import AccordionApi from "Components/AccordionApi";

import awardCategory1 from "Assets/awards/awardCategory1.svg";
import awardCategory2 from "Assets/awards/awardCategory2.svg";
import awardCategory3 from "Assets/awards/awardCategory3.svg";
import awardCategory4 from "Assets/awards/awardCategory4.svg";
import awardCategory5 from "Assets/awards/awardCategory5.svg";
import awardCategory6 from "Assets/awards/awardCategory6.svg";
import awardCategory7 from "Assets/awards/awardCategory7.svg";
import awardCategory8 from "Assets/awards/awardCategory8.svg";
import awardCategory9 from "Assets/awards/awardCategory9.svg";
import awardCategory10 from "Assets/awards/awardCategory10.svg";
import awardCategory11 from "Assets/awards/awardCategory11.svg";
import awardCategory12 from "Assets/awards/awardCategory12.svg";
import awardCategory13 from "Assets/awards/awardCategory13.svg";

import { useQuery } from "react-query";
import { AWARDDETAIL } from "Constants/QueriesKeys";
import { http } from "Utils/Http/Http";
import SafeHTML from "Components/SafeHTML/SafeHTML";
import HeroBox from "Components/TitleBox/HeroBox";

const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: "capitalize",
    fontSize: "18px",
    color: "rgba(0,0,0,1)",
  },
  h1Label: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "72px",
  },
  h1Text: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "16px",
  },
  commingSoon: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "70px",
    lineHeight: "58px",
    marginBottom: "10px",
  },
  text1: {
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    marginBottom: "50px",
  },
  h3text: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "26px",
    color: "black",
    marginBottom: "24px",
    textAlign: "center",
  },
  ptext: {
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "37px",
    color: "#5D6180",
  },
  cardText: {
    fontStyle: "normal",
    fontSize: "18px",
    color: "rgba(0,0,0,0.4)!important",
    cursor: "pointer",
    marginBottom: "10px",
    "&:hover": {
      color: "#5D6180 !important",
    },
  },
  iconColor: {
    height: "25px",
  },
  categoryCardInActive: {
    borderRadius: "20px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)",
    "&:hover": {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  },
  FAQPolicyCard: {
    minHeight: "300px",
    maxWidth: "1000px",
    background: "rgba(0,0,0,0.05)",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5vh",
    marginBottom: "10vh",
    borderRadius: "30px",
    backgroundImage: `url(${FAQPolicyCard})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  helpText: {
    fontStyle: "normal",
    fontSize: "18px",
    color: "black",
    marginBottom: "10px",
    textAlign: "center",
  },
  btn_policyFAQ: {
    color: "black",
    padding: "10px 100px",
    backgroundColor: "white",
    borderRadius: "5px",
    "&:hover": {
      // backgroundColor: "rgba(61, 141, 187,0.1)",
      backgroundColor: color2,
      color: "white",
    },
  },
  hoverAbleImage: {
    "& img": {
      filter: "grayscale(100%)",
    },
    "&:hover": {
      "& img": { filter: "grayscale(0%)" },
    },
  },
  awardSteps: {
    padding: "50px 50px 0",
  },
  awardSatgesLine: {
    height: "2px",
    backgroundColor: "#5D6180",
    position: "absolute",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    zIndex: "-1",
    top: "22%",
    left: "11%",
    width: " 78%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  awardSteps__item: {
    maxWidth: "200px",
  },
  awardSteps__Number: {
    width: "20px",
    height: "20px",
    backgroundColor: "#9fcf9c",
    borderRadius: "50%",
    display: "inline-block",
    margin: "12px auto",
  },
  awardSteps__Text: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#5D6180",
  },
  suggestedItemBox: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "25%",
    },
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    "&:hover": {
      color: "#9fcf9c",
      cursor: "select",
      transition: "color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& img": {
      marginBottom: "20px",
      filter: "grayscale(100%)",
      transition: "filter 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:hover img": {
      filter: "grayscale(0%)",
    },
  },
  media: {
    maxWidth: "500px", //this gives me full width
    height: "auto",
  },
}));

const ActiveModalContent = ({ activeItem }) => {
  return (
    <div style={{ marginRight: "20px" }}>
      <SafeHTML variant="body1" component={"div"}>
        {activeItem.listText}
      </SafeHTML>
    </div>
  );
};

const CustomModalContentForAwards = ({ activeItem, t }) => {
  const titleText = ["المركز الأول", "المركز الثاني", "المركز الثالث"];
  const colors = ["#fdc103", "#c8c8c8", "#fdbd9c"];
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-evenly"}
      >
        <Box flex={1} sx={{}}></Box>
        <Box flex={1} sx={{ marginX: 2 }}>
          <Typography sx={{ marginY: 2, textAlign: "center" }}>
            الأفراد
          </Typography>
        </Box>
        <Box flex={1} sx={{ marginX: 2 }}>
          <Typography
            sx={{
              marginY: 2,
              textAlign: "center",
            }}
          >
            طلبة الجامعات
          </Typography>
        </Box>
        <Box flex={1} sx={{ marginX: 2 }}>
          <Typography sx={{ marginY: 2, textAlign: "center" }}>
            طلبة المدارس
          </Typography>
        </Box>
      </Box>

      {activeItem.map((item, index) => (
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-evenly"}
          sx={{
            mt: 4,
          }}
        >
          <Box flex={1} sx={{}}>
            <Typography sx={{ color: colors[index] }}>
              {titleText[index]}
            </Typography>
          </Box>
          <Box
            flex={1}
            sx={{
              backgroundColor: colors[index],
              textAlign: "center",
              borderRadius: 2,
              marginX: 2,
            }}
          >
            <Typography sx={{ marginY: 10 }}>{item[1]}</Typography>
          </Box>
          <Box
            flex={1}
            sx={{
              backgroundColor: colors[index],
              textAlign: "center",
              borderRadius: 2,
              marginX: 2,
            }}
          >
            <Typography sx={{ marginY: 10 }}>{item[2]}</Typography>
          </Box>
          <Box
            flex={1}
            sx={{
              backgroundColor: colors[index],
              textAlign: "center",
              borderRadius: 2,
              marginX: 2,
            }}
          >
            <Typography sx={{ marginY: 10 }}>{item[3]}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const AwardPage = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [openPolciyModal, setOpenPolciyModal] = useState(false);
  const [openCriteriaModal, setOpenCriteriaModal] = useState(false);
  const [openAwardsModal, setOpenAwardsModal] = useState(false);
  const [activePolicy, setActivePolicy] = useState();
  const [activeCriteria, setActiveCriteria] = useState();
  const [activeAwards, setActiveAwards] = useState();
  const [track, setTrack] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [policyCriteriaPhotography, setPolicyCriteriaPhotography] = useState();
  const [policyCriteriaVideography, setPolicyCriteriaVideography] = useState();
  const [policyCriteriaDrawing, setPolicyCriteriaDrawing] = useState();
  const [policyCriteriaWriting, setPolicyCriteriaWriting] = useState();
  const [termsConditionss, setTermsConditionss] = useState();
  const [heroBoxDetails, setHeroBoxDetails] = useState();

  const awardPageQuery = useQuery([AWARDDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      return (
        setPolicyCriteriaPhotography(
          data?.data?.gulfSmartInvestorAward?.photography
        ),
        setPolicyCriteriaVideography(
          data?.data?.gulfSmartInvestorAward?.videography
        ),
        setPolicyCriteriaDrawing(data?.data?.gulfSmartInvestorAward?.drawing),
        setTermsConditionss(
          data?.data?.gulfSmartInvestorAward?.accordianDetails
        ),
        setPolicyCriteriaWriting(data?.data?.gulfSmartInvestorAward?.writing),
        setHeroBoxDetails(data?.data?.gulfSmartInvestorAward?.headerDetails),
        data
      );
    })
  );

  const handleSuccessModalOpen = () => {
    setSuccessModalOpen(true);
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const handleClose = () => {
    setTrack(null);
  };

  const handleClosePolicyModal = () => {
    setOpenPolciyModal(false);
  };

  const policyModalHandler = (item) => {
    setOpenPolciyModal(true);
    setActivePolicy(item);
  };

  const criteriaModalHandler = (item) => {
    setActiveCriteria(item);
    setOpenCriteriaModal(true);
  };
  const awardsModalHandler = (item) => {
    setActiveAwards(item);
    console.log(item);
    setOpenAwardsModal(true);
  };

  const handleCloseAwardsModal = () => {
    setOpenAwardsModal(false);
  };
  const handleCloseCriteriaModal = () => {
    setOpenCriteriaModal(false);
  };

  const awardsCategoryArray = [
    {
      id: "video",
      title: t("award.videography"),
      icon: {
        src: VideoIconColored,
      },
      path: "/smart-khaliji-investor-award/videography",
      policy: policyCriteriaVideography?.policy,
      criteria: policyCriteriaVideography?.criteria,
      awards: [
        {
          path: "professional",
          1: "100 ألف ريال سعودي",
          2: "75 ألف ريال سعودي",
          3: "50 ألف ريال سعودي",
        },
        {
          path: "university",
          1: "75 ألف ريال سعودي",
          2: "50 ألف ريال سعودي",
          3: "25 ألف ريال سعودي",
        },
        {
          path: "school",
          1: "50 ألف ريال سعودي",
          2: "25 ألف ريال سعودي",
          3: "15 ألف ريال سعودي",
        },
      ],
    },
    {
      id: "drawing",
      title: t("award.drawing"),
      icon: {
        src: PencilIconColored,
      },
      path: "/smart-khaliji-investor-award/drawing",
      policy: policyCriteriaDrawing?.policy,
      criteria: policyCriteriaDrawing?.criteria,
      awards: [
        {
          path: "professional",
          1: "75 ألف ريال سعودي",
          2: "50 ألف ريال سعودي",
          3: "25 ألف ريال سعودي",
        },
        {
          path: "university",
          1: "50 ألف ريال سعودي",
          2: "25 ألف ريال سعودي",
          3: "15 ألف ريال سعودي",
        },
        {
          path: "school",
          1: "25 ألف ريال سعودي",
          2: "15 ألف ريال سعودي",
          3: "10 آلاف ريال سعودي",
        },
      ],
    },
    {
      id: "photo",
      title: t("award.photographer"),
      icon: {
        src: PhotoIconColored,
      },
      path: "/smart-khaliji-investor-award/photography",
      policy: policyCriteriaPhotography?.policy,
      criteria: policyCriteriaPhotography?.criteria,
      awards: [
        {
          path: "professional",
          1: "50 ألف ريال سعودي",
          2: "25 ألف ريال سعودي",
          3: "15 ألف ريال سعودي",
        },
        {
          path: "university",
          1: "25 ألف ريال سعودي",
          2: "15 ألف ريال سعودي",
          3: "10 آلاف ريال سعودي",
        },
        {
          path: "school",
          1: "15 ألف ريال سعودي",
          2: "10 آلاف ريال سعودي",
          3: "5 آلاف ريال سعودي",
        },
      ],
    },

    {
      id: "writing",
      title: t("award.writing"),
      icon: {
        src: WritingIconColored,
      },
      path: "/smart-khaliji-investor-award/writing",
      policy: policyCriteriaWriting?.policy,
      criteria: policyCriteriaWriting?.criteria,
      awards: [
        {
          path: "professional",
          1: "25 ألف ريال سعودي",
          2: "15 ألف ريال سعودي",
          3: "10 آلاف ريال سعودي",
        },
        {
          path: "university",
          1: "15 ألف ريال سعودي",
          2: "10 آلاف ريال سعودي",
          3: "5 آلاف ريال سعودي",
        },
        {
          path: "school",
          1: "10 آلاف ريال سعودي",
          2: "5 آلاف ريال سعودي",
          3: "3 آلاف ريال سعودي",
        },
      ],
    },
  ];
  const awardParticipatCategories = [
    {
      id: "university",
      title: t("awardForm.awardParticipatCategories.student_university"),
      icon: UniversityIcon,
      description: t(
        "awardForm.awardParticipatCategories.student_university_desc_title"
      ),
    },
    {
      id: "school",
      title: t("awardForm.awardParticipatCategories.student_school"),
      icon: SchoolIcon,
      description: t(
        "awardForm.awardParticipatCategories.student_school_desc_title"
      ),
    },
    {
      id: "professional",
      title: t("awardForm.awardParticipatCategories.professional"),
      icon: ProfessionalIcon,
      description: t(
        "awardForm.awardParticipatCategories.professional_desc_title"
      ),
    },
  ];

  const awardStages = {
    title: t("awardStages.title"),
    list: [
      {
        title: t("awardStages.text1"),
        text: t("awardStages.subText1"),
      },
      {
        title: t("awardStages.text2"),
        text: t("awardStages.subText2"),
      },
      {
        title: t("awardStages.text6"),
        text: t("awardStages.subText6"),
      },
      {
        title: t("awardStages.text5"),
        text: t("awardStages.subText5"),
      },
    ],
  };

  const suggestedTopics = {
    title: t("suggestedTopics.title"),
    paragraph: t("suggestedTopics.paragraph"),
    list: [
      {
        title: t("suggestedTopics.text1"),
        icon: awardCategory5,
      },
      {
        title: t("suggestedTopics.text2"),
        icon: awardCategory4,
      },
      {
        title: t("suggestedTopics.text3"),
        icon: awardCategory3,
      },
      {
        title: t("suggestedTopics.text4"),
        icon: awardCategory2,
      },
      {
        title: t("suggestedTopics.text5"),
        icon: awardCategory1,
      },
      {
        title: t("suggestedTopics.text6"),
        icon: awardCategory10,
      },
      {
        title: t("suggestedTopics.text7"),
        icon: awardCategory9,
      },
      {
        title: t("suggestedTopics.text8"),
        icon: awardCategory8,
      },
      {
        title: t("suggestedTopics.text9"),
        icon: awardCategory7,
      },
      {
        title: t("suggestedTopics.text10"),
        icon: awardCategory6,
      },
      {
        title: t("suggestedTopics.text11"),
        icon: awardCategory13,
      },
      {
        title: t("suggestedTopics.text12"),
        icon: awardCategory12,
      },
      {
        title: t("suggestedTopics.text13"),
        icon: awardCategory11,
      },
    ],
  };

  const detailsData1 = [
    {
      title: t("award.title1"),
      icon: {
        src: AwardIconColored,
      },
      // text: t('award.text1'),
      text: awardPageQuery?.data?.data?.gulfSmartInvestorAward?.aboutAward,
    },
    {
      title: t("award.title2"),
      icon: {
        src: AwardIconColored,
      },
      // text: t('award.text2'),
      text: awardPageQuery?.data?.data?.gulfSmartInvestorAward
        ?.targetedAudience,
    },
  ];

  return (
    <Box className={classes.contactUs}>
      <HeroBox heroBoxDetails={heroBoxDetails} textMaxWidth="500px" />
      <Grid
        container
        spacing={3}
        style={{
          padding: "0 5%",
          paddingBottom: "7vh",
          paddingTop: "7vh",
          background: "#F8F8F8",
        }}
      >
        <RewardMoney />

        <Grid item xs={12} marginTop={10} marginBottom={10}>
          {/* <h3 className={classes.h3text}>{t("award.formTitle")}</h3> */}
          <h3 className={classes.h3text}>{t("award.registerationEnded")}</h3>
          <h3 className={classes.h3text}>
            {"وسيتم الإعلان عن الفائزين قريبًا"}
          </h3>
        </Grid>

        {/* <Grid item xs={12} marginTop={10} marginBottom={10}>
          <h3 className={classes.h3text}>
            {"جهزوا ابداعاتكم قريباً راح يتم فتح باب المشاركات"}
          </h3>
        </Grid> */}
        {/* <FormModal
          open={true}
          track={track}
          awardsCategoryArray={awardsCategoryArray}
          handleClose={handleClose}
          handleSuccessModalOpen={handleSuccessModalOpen}
          awardParticipatCategories={awardParticipatCategories}
        /> */}

        <Grid item marginTop={10} xs={12}>
          <Grid item xs={12}>
            <h3 className={classes.h3text}>{"فئات المشاركة"}</h3>
          </Grid>
          <Grid
            container
            spacing={5}
            maxWidth={"lg"}
            marginX={"auto"}
            justifyContent="center"
          >
            {awardParticipatCategories?.map((item) => {
              return (
                <Grid item xs={12} md={4}>
                  <Card
                    style={{ height: 450 }}
                    className={[
                      classes.categoryCardInActive,
                      classes.hoverAbleImage,
                    ].join(" ")}
                    onMouseEnter={() => null}
                  >
                    <Box
                      flexDirection={"column"}
                      display={"flex"}
                      style={{
                        padding: "10px 10px 0 10px",
                        height: "100%",
                      }}
                    >
                      <Box
                        item
                        flex={1}
                        display={"flex"}
                        flexDirection={"column"}
                        style={{
                          marginBottom: "20px",
                          background: "#F8FAFB",
                          borderRadius: 6,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "210px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={item?.icon}
                          sx={{
                            height: "100%",
                            maxHeight: "100px",
                            maxWidth: "100%",
                            marginBottom: "10px",
                          }}
                        ></Box>
                        <p>{item.title}</p>
                      </Box>
                      <Box
                        flex={1}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={{
                          width: "100%",
                          paddingRight: 6,
                          paddingLeft: 6,
                          justifyContent: "center",
                        }}
                      >
                        <p className={"text-gray-600 text-justify"}>
                          {item.description}
                        </p>
                      </Box>
                    </Box>
                    {/* <Grid container style={{ padding: "0px 20px 20px 20px" }}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="space-between"
                          className={classes.cardText}
                          onClick={() => policyModalHandler(item?.policy)}
                        >
                          <Grid item>
                            <h3>{t("award.policy")}</h3>
                          </Grid>
                          <Grid item>
                            {i18n.language === "ar" ? (
                              <ArrowCircleLeftOutlinedIcon
                                className={classes.iconColor}
                              />
                            ) : (
                              <ArrowCircleRightOutlinedIcon
                                className={classes.iconColor}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="space-between"
                          className={classes.cardText}
                          onClick={() => criteriaModalHandler(item?.criteria)}
                        >
                          <Grid item>
                            <h3>{t("award.judgingCriteria")}</h3>
                          </Grid>
                          <Grid item>
                            {i18n.language === "ar" ? (
                              <ArrowCircleLeftOutlinedIcon
                                className={classes.iconColor}
                              />
                            ) : (
                              <ArrowCircleRightOutlinedIcon
                                className={classes.iconColor}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item marginTop={10} xs={12} style={{}}>
          <Grid item xs={12}>
            <h3 className={classes.h3text}>{t("award.title3")}</h3>
          </Grid>
          <Grid
            container
            spacing={5}
            maxWidth={"lg"}
            marginX={"auto"}
            justifyContent="center"
          >
            {awardsCategoryArray?.map((item) => {
              return (
                <Grid item xs={12} md={3} style={{}}>
                  <Card
                    style={
                      item?.id === track?.id
                        ? {
                            borderColor: "#9fcf9c",
                            borderWidth: 3,
                            borderStyle: "solid",
                          }
                        : {}
                    }
                    className={[
                      classes.categoryCardInActive,
                      classes.hoverAbleImage,
                    ].join(" ")}
                    onMouseEnter={() => null}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        flexDirection: "column",
                        marginBottom: "15px",
                        // cursor: 'pointer',
                        padding: "10px 10px 0 10px",
                      }}
                      // onClick={() => history.push(item.path)}
                    >
                      <Grid
                        item
                        style={{
                          marginBottom: "20px",
                          background: "#F8FAFB",
                          borderRadius: 6,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "210px",
                        }}
                      >
                        <img
                          src={item?.icon?.src}
                          alt=""
                          style={{ height: "100%", maxHeight: "100px" }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <p>{item?.title}</p>
                      </Grid>
                    </Grid>
                    <Grid container style={{ padding: "0px 20px 20px 20px" }}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="space-between"
                          className={classes.cardText}
                          onClick={() => policyModalHandler(item?.policy)}
                        >
                          <Grid item>
                            <h3>{t("award.policy")}</h3>
                          </Grid>
                          <Grid item>
                            {i18n.language === "ar" ? (
                              <ArrowCircleLeftOutlinedIcon
                                className={classes.iconColor}
                              />
                            ) : (
                              <ArrowCircleRightOutlinedIcon
                                className={classes.iconColor}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="space-between"
                          className={classes.cardText}
                          onClick={() => criteriaModalHandler(item?.criteria)}
                        >
                          <Grid item>
                            <h3>{t("award.judgingCriteria")}</h3>
                          </Grid>
                          <Grid item>
                            {i18n.language === "ar" ? (
                              <ArrowCircleLeftOutlinedIcon
                                className={classes.iconColor}
                              />
                            ) : (
                              <ArrowCircleRightOutlinedIcon
                                className={classes.iconColor}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="space-between"
                          className={classes.cardText}
                          onClick={() => awardsModalHandler(item)}
                        >
                          <Grid item>
                            <h3>{t("award.awards")}</h3>
                          </Grid>
                          <Grid item>
                            {i18n.language === "ar" ? (
                              <ArrowCircleLeftOutlinedIcon
                                className={classes.iconColor}
                              />
                            ) : (
                              <ArrowCircleRightOutlinedIcon
                                className={classes.iconColor}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {/*  */}
      <Grid container spacing={0} justifyContent="center">
        {detailsData1?.map((item) => {
          return (
            <Grid item xs={12} md={5} style={{ maxWidth: 320 }}>
              <FlipcardHex data={item} />
            </Grid>
          );
        })}
      </Grid>
      {/*  */}
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: "5% 0",
        }}
      >
        <iframe
          width="1024"
          height="500"
          src="https://www.youtube.com/embed/iW0B7HyJc9c"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Grid>
      {/*  */}
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: "5% 0",
        }}
        // margin={"0 auto"}
        spacing={2}
      >
        <h3 className={classes.h3text} style={{ textAlign: "center" }}>
          {/* {awardStages.title} */}
          آخر موعد لاستقبال مشاركاتكم
        </h3>

        <Grid display={"flex"} xs={12} className={classes.awardSteps}>
          <Grid
            container
            columnSpacing={{ xs: 3, sm: 8, md: 10 }}
            alignItems="center"
            justifyContent={"center"}
            style={{
              position: "relative",
              width: "auto",
              margin: "0 auto",
            }}
          >
            {/* <Box
              height={"1px"}
              width={"100%"}
              bgcolor="ActiveBorder"
              className={classes.awardSatgesLine}
            ></Box> */}
            <p className={classes.p} style={{ textAlign: "center" }}>
              {/* {awardStages.title} */}
              29 فبراير 2024
            </p>
            {/* {awardStages.list.map((item, id) => (
              <Grid
                display={"flex"}
                flexDirection={"column"}
                textAlign={"center"}
                alignItems={"center"}
                minWidth={"200px"}
                xs="12"
                md="auto"
                key={id}
                item
                className={classes.awardSteps__Item}
              >
                <span className={classes.awardSteps__Number}></span>
                <div className={classes.awardSteps__Text}>
                  <Typography variant="h6" component="h6">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" component="div">
                    {item.text}
                  </Typography>
                </div>
              </Grid>
            ))} */}
          </Grid>
        </Grid>
      </Grid>
      {/*  */}

      {/*  */}
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: "7vh 0",
        }}
        margin={"0 auto"}
        spacing={2}
        maxWidth="xl"
      >
        <h3 className={classes.h3text} style={{ textAlign: "center" }}>
          {suggestedTopics.title}
        </h3>
        <Grid
          container
          alignItems={"center"}
          justifyContent="center"
          paddingBottom={"40px"}
        >
          <Typography variant="body1" component="div">
            {suggestedTopics.paragraph}
          </Typography>
        </Grid>
        <Grid display={"flex"} xs={12} className={classes.awardSteps}>
          <Grid
            container
            alignItems={"center"}
            justifyContent="center"
            rowSpacing={10}
            columnSpacing={{ xs: 8, sm: 8, md: 10 }}
            paddingBottom={10}
          >
            {suggestedTopics.list.map((item, id) => (
              <Grid key={id} className={classes.suggestedItemBox} item>
                <img src={item.icon} />
                <Typography
                  whiteSpace={"nowrap"}
                  className={classes.suggestedTopics__title}
                  variant="body2"
                  component="h6"
                >
                  {item.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {/*  */}
      <Box>
        <h3 className={classes.h3text} style={{ textAlign: "center" }}>
          {"الرعاة"}
        </h3>
        <Grid display={"flex"} xs={12} className={classes.awardSteps}>
          <Grid
            container
            alignItems={"center"}
            justifyContent="center"
            rowSpacing={10}
            columnSpacing={{ xs: 8, sm: 8, md: 10 }}
            paddingBottom={10}
          >
            <Grid className={{}} item>
              <img src={SponserArgam} width={200} />
              {/* <Typography
                  whiteSpace={"nowrap"}
                  className={classes.suggestedTopics__title}
                  variant="body2"
                  component="h6"
                >
                  {item.title}
                </Typography> */}
            </Grid>
            <Grid className={{}} item>
              <img src={SponserTetco} width={200} />
              {/* <Typography
                  whiteSpace={"nowrap"}
                  className={classes.suggestedTopics__title}
                  variant="body2"
                  component="h6"
                >
                  {item.title}
                </Typography> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/*  */}
      <Grid
        container
        maxWidth={"lg"}
        margin={"0 auto"}
        style={{
          padding: "3vh 0",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          style={{
            marginTop: "-50px",
            transform: "translateY(-50)",
          }}
        >
          {termsConditionss && <AccordionApi items={termsConditionss} />}
        </Grid>
      </Grid>
      {/*  */}
      <AwardCommonModal
        open={openCriteriaModal}
        handleClose={handleCloseCriteriaModal}
        handleSuccessModalOpen={handleSuccessModalOpen}
        title={t("award.judgingCriteria")}
      >
        {activeCriteria && <ActiveModalContent activeItem={activeCriteria} />}
      </AwardCommonModal>
      <AwardCommonModal
        open={openAwardsModal}
        handleClose={handleCloseAwardsModal}
        handleSuccessModalOpen={handleSuccessModalOpen}
        title={"جوائز مسار " + activeAwards?.title ?? ""}
      >
        {activeAwards && (
          <CustomModalContentForAwards t={t} activeItem={activeAwards.awards} />
        )}
      </AwardCommonModal>
      <AwardCommonModal
        open={openPolciyModal}
        handleClose={handleClosePolicyModal}
        handleSuccessModalOpen={handleSuccessModalOpen}
        title={activePolicy?.title}
        maxWidth="sm"
      >
        {activePolicy && <ActiveModalContent activeItem={activePolicy} />}
      </AwardCommonModal>

      <FormSubmitModal
        open={successModalOpen}
        handleClose={handleSuccessModalClose}
      />
    </Box>
  );
};

export default AwardPage;
