
import React from 'react';
import logo from 'Assets/mlmlogo.svg';
import footer from 'Assets/footer.svg';
import { useTranslation } from 'react-i18next';
import { footerSections } from './Constants/FooterSections';
import { NavLink } from 'react-router-dom';
import { BtnBase } from 'Components';
import { makeStyles } from '@mui/styles';
import { Container, Divider, Grid } from '@mui/material';
import twiterIcon from 'Assets/commingSoon/twitterIcon.svg';
import instagramIcon from 'Assets/commingSoon/instagramIcon.svg';
import youtubeIcon from 'Assets/commingSoon/youtubeIcon.svg';
import line from 'Assets/line.svg';
import footerLogo from 'Assets/footerLogo.png';



const useStyle = makeStyles((theme) => ({
    footerFirstSection: {
        backgroundColor: theme.palette.background.paper,
        padding: `${theme.spacing(12)} ${theme.spacing(3)}`,
    },
    footerSecondSection: {
        padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
        color: "rgba(118, 118, 118, 1)"
    },
    navLink: {
        color: '#000',
        margin: '15px',
    },
    footer: {
        padding: '16px 16px',
        [theme.breakpoints.up("sm")]: {
           padding: '16px 16px',
        },
        [theme.breakpoints.up("md")]: {
            padding: '16px 60px',
        }
    },
    footerBackground: {
        top: "0%",
        // background: `url(${footer})`,
        width: '100%',
        height: '100%',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "bottom center",
        display: "block",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    mobile: {
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "block",
            textAlign: "center"
        },
    },
    footerLogo: {
        display: 'block',
        margin: '0 auto'
    },
    footerPaddingWrap: {
        padding: "12px",
        [theme.breakpoints.up("lg")]: {
          padding: '0 60px',
        },
        [theme.breakpoints.up("xl")]: {
            padding: "0px",
        },
    },
    footerLine: {
        width: "100%",
        height: "3px",
        background: `url(${line}) no-repeat center center / 100%`,
    }
}));

export default function Footer() {
    const classes = useStyle();
    const { t } = useTranslation();


    const linksArray = [
        {
            icon: youtubeIcon,
            path: "https://www.youtube.com/channel/UChrdz7OmRR7OaOZ3Dl_WkWA"
        },
        {
            icon: instagramIcon,
            path: "https://instagram.com/mulimgcc"
        },
        {
            icon: twiterIcon,
            path: "https://twitter.com/mulimgcc"
        },
    ]

    return (
        <>
            <div className={classes.footerBackground}>
                <Grid container justifyContent="center" className={classes.footerLine}>
                </Grid>

                <Container maxWidth="xl" className={classes.footerPaddingWrap} >
                    <Grid container direction='row' justifyContent="space-between" alignItems='center' className={classes.footer}>
                        <Grid item >
                            <NavLink className={classes.navLink} to="/">
                                <img src={logo} alt="Logo" />
                            </NavLink>
                        </Grid>
                        <Grid item >
                            <Grid container>
                                {footerSections()?.map((link, index) => (
                                    <Grid item className={classes.navLink}>
                                        <NavLink to={link.path}>
                                            {link.text}
                                        </NavLink>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Grid container spacing={5}>
                                {linksArray?.map((item) => {
                                    return (
                                        <Grid item>
                                            <a target="blank" href={item.path}>
                                                <img src={item.icon} alt='' style={{ width: "25px", cursor: "pointer" }} />
                                            </a>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>

                <Container maxWidth="xl" className={classes.footerPaddingWrap} justifyContent="center">
                   <Grid container justifyContent="center" className={classes.footerLine}>
                   </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item >
                            <div className={classes.footerSecondSection}>
                                <Container maxWidth="xl">
                                    <div className="flex justify-center mb-5" style={{alignItems:'center'}}>
                                    <img src={footerLogo} alt="" style={{ height: "60px"}} />
                                    <div style={{
                                        height: "40px",
                                        width: "1px",
                                        background: "rgb(204 204 204 / 50%)",
                                        margin: "0px 45px"
                                    }}></div>
                                    <p>
                                        {t("footer.copyRight")}
                                    </p>
                                    </div>
                                </Container>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className={classes.mobile}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <img src={line} alt="" style={{ height: "2px", margin: "0 auto" }} />
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent="center" alignItems='center'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <NavLink className={classes.navLink} to="/">
                            <img className={classes.footerLogo} src={logo} alt="Logo" />
                        </NavLink>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ul>
                            {footerSections()?.map((link, index) => (
                                <NavLink className={classes.navLink} to={link.path}>
                                    <li>{link.text}</li>
                                </NavLink>
                            ))}
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={5} justifyContent="center">
                            {linksArray?.map((item) => {
                                return (
                                    <Grid item>
                                        <a target="blank" href={item.path}>
                                            <img src={item.icon} alt='' style={{ width: "20px", cursor: "pointer" }} />
                                        </a>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item xs={10}>
                            <img src={line} alt="" style={{ height: "2px",margin:'5px'}} />
                        </Grid>
                    </Grid>
                    <div className="flex justify-center mb-5" style={{alignItems:'center',marginTop:'5px'}}>
                    <img src={footerLogo} alt="" style={{ height: "50px",textAlign:'center'}} />
                    </div>
                    <Grid item xs={12} style={{
                        marginBottom: "20px"
                    }}>
                    <p>
                        {t("footer.copyRight")}
                    </p>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
